import React, {useState} from 'react';
import {Form, Row, Col, Button} from 'react-bootstrap';
import {useFormContext,useForm} from 'react-hook-form';
import YearMonth from './yearMonth';


const Infringements = () => {
	const {
		register,
		unregister,
		setValue,
		formState: {errors}
	} = useFormContext() || useForm();

	const [indexes, setIndexes] = useState([0]);
	const [counter, setCounter] = useState(1);

	const addInfringement = () => {
		setIndexes((prevIndexes) => [...prevIndexes, counter]);
		setCounter((prevCounter) => prevCounter + 1);
	};

	const removeInfringement = (index) => {
		setIndexes((prevIndexes) => [
			...prevIndexes.filter((item) => item !== index)
		]);
		unregister(`infringements[${index}].type`);
		unregister(`infringements[${index}].year`);
		unregister(`infringements[${index}].month`);
	};

	const infringementTypes = [
		'Loss of licence - Up to 3 months',
		'Loss of licence - 3-6 months',
		'Loss of licence - 12+ months'
	];

	return (
		<>
			<Row>
				<Col>Description</Col>
				<Col lg={2}>Year</Col>
				<Col lg={1}>Month</Col>
				<Col></Col>
			</Row>

			{indexes.map((index) => {
				const fieldName = `infringements[${index}]`;
				register(`${fieldName}.type`);

				let descInvalid = false;

				if (
					errors.infringements &&
					errors.infringements[`${index}`] !== undefined
				) {
					descInvalid = errors.infringements[`${index}`].type !== undefined;
				}

				return (
					<Row className='mb-3' key={fieldName}>
						<Form.Group as={Col} controlId={`${fieldName}.type`}>
							<Form.Select
								name={`${fieldName}.type`}
								onChange={(e) => setValue(`${fieldName}.type`, e.target.value)} isInvalid={descInvalid}
							>
                <option>-- Please select --</option>
								{infringementTypes.map((type) => {
									return <option value={type}>{type}</option>;
								})}
							</Form.Select>
							{descInvalid && (
								<Form.Control.Feedback type='invalid'>
										{errors.infringements[`${index}`].type.message}
								</Form.Control.Feedback>
							)}
						</Form.Group>
						<YearMonth fieldName={fieldName}/> 

						<Col>
							{indexes[0] !== index && (
								<Button onClick={() => removeInfringement(index)}>
									Remove
								</Button>
							)}{' '}
						</Col>
					</Row>
				);
			})}

			<Row className='mb-5'>
				<Col>
					<Button onClick={addInfringement}>Add more</Button>
				</Col>
			</Row>

		</>
	);
};

export default Infringements;
